
import { Component, Mixins } from 'vue-property-decorator';
import FormLogin from './';
const Words: Dictionary = {
  error: 'Corporate members account or password is incorrect.',
  account: 'corporate member account',
  password: 'password',
  login: 'LOGIN',
};
@Component
export default class FormLoginJa extends Mixins(FormLogin) {
  private words = Words;
}
