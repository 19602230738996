
import { Component, Mixins } from 'vue-property-decorator';
import HomeHistory from '@/components/home/HomeHistory';
import HomeHistoryItem from './HomeHistoryItem/HomeHistoryItemEn.vue';
const Words: Dictionary = {
  title01: `<span class="en">HISTORY</span>`,
  link: {
    open: 'Show all',
    delete: 'All delete',
  },
  close: 'Close',
  btMoreSp: 'Show all History',
};
@Component({
  components: {
    HomeHistoryItem,
  },
})
export default class HomeHistoryEn extends Mixins(HomeHistory) {
  private words = Words;
}
