
import { Component, Mixins } from 'vue-property-decorator';
import BoxListItem from './';
const Words: Dictionary = {
  btReserve: 'BOOK NOW',
  btOfficialSite: 'OFFICIAL SITE',
};
@Component
export default class BoxListItemEn extends Mixins(BoxListItem) {
  private words = Words;
}
