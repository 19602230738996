
import { Component, Mixins } from 'vue-property-decorator';
import HomeFavoritelist from './';
import HomeFavoritelistItem from './HomeFavoritelistItem/HomeFavoritelistItemEn.vue';
const Words: Dictionary = {
  title01: `<span class="en">FAVORITE LIST</span>`,
  close: 'Close',
  link: {
    open: 'Show all',
    edit: 'Edit',
  },
  btMoreSp: 'Show all Favorite List',
};
@Component({
  components: {
    HomeFavoritelistItem,
  },
})
export default class HomeFavoritelistJa extends Mixins(HomeFavoritelist) {
  private words = Words;
}
