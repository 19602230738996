import { Component, Vue, Emit } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import store from '@/store';
import VueRouter from 'vue-router';
// @ is an alias to /src
@Component
export default class FormLogin extends Vue {
  private name = 'FormLogin';
  private username = null;
  private password = null;
  private error: boolean|null = null;
  get lang(): string {
    return store.getters['auth/lang'];
  }
  @Emit()
  private onSubmit() {
    const params = {
      username: this.username,
      password: this.password,
    };
    store.dispatch('auth/login', params).then((response: any) => {
      this.password = null;
      if (response) {
        this.error = false;
        if (response.data.redirectUrl) {
          this.username = null;
          location.href = response.data.redirectUrl;
        } else if (this.$route.query.redirect) {
          this.$router.push(String(this.$route.query.redirect));
        } else {
          let url = '/' + this.lang;
          if (this.lang === 'ja') {
            url = '/';
          }
          this.$router.push(url);
        }
      } else {
        this.error = true;
      }
    })
    .catch( (error: any) => {
      this.error = true;
    });
  }
}
