
import { Component, Mixins, Emit } from 'vue-property-decorator';
import Login from '@/mixins/Login';
import FormLogin from '@/components/form/FormLogin/FormLoginEn.vue';
import store from '@/store';

// @ is an alias to /src
@Component({
  components: {
    FormLogin,
  },
})
export default class LoginEn extends Mixins(Login) {
  private mounted() {
    store.commit('loaded', true);
  }
}
