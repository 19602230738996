
import { Component, Mixins, Emit } from 'vue-property-decorator';
import Login from '@/mixins/Login';
import FormLogin from '@/components/form/FormLogin/FormLogin.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import VueRouter from 'vue-router';

Component.registerHooks([
  'beforeRouteEnter',
]);

// @ is an alias to /src
@Component({
  components: {
    FormLogin,
  },
})
export default class LoginJa extends Mixins(Login) {
  private banner: BannerData[] = [];

  private async beforeRouteEnter(to: any, from: VueRouter, next: any) {
    let resBanner: BannerData[] = [];
    const res = await axios.get(`/api/wp-json/31corp/v1/banners?cat=login-page`);
    if (res.status === 200) {
      resBanner = res.data;
    }
    next((vm: {banner: any}) => {
      vm.banner = resBanner;
    });
  }

  // private created() {
  //   axios.get(`/api/wp-json/31corp/v1/banners?cat=login-page`)
  //   .then(res => {
  //     console.log(res)
  //     this.banner = res.data;
  //   })
  // }

  private mounted() {
    store.commit('loaded', true);
  }
}
