
import { Component, Mixins } from 'vue-property-decorator';
import FormLogin from './';
const Words: Dictionary = {
  error: '法人会員様専用アカウント<br>またはパスワードに誤りがあります。',
  account: 'アカウント',
  password: 'パスワード',
  login: 'ログイン',
};
@Component
export default class FormLoginJa extends Mixins(FormLogin) {
  private words = Words;
}
